@keyframes cirani {
    0%{
        transform: scale(0,0);
        fill: rgb(104,162,253);
        stroke: rgb(104,162,253);
    }
    100%{
        transform: scale(1,1);
        fill: rgb(104,162,253);
        stroke: rgb(104,162,253);
    }
}
@keyframes senani1{
    0%{
        transform: scale(0,0);
    }
    25%{
        stroke-dashoffset: 40;
        transform: scale(0,0);
    }
    100%{
        stroke-dashoffset: 10;
        transform: scale(1,1);
    }
}
@keyframes senani2 {
    0%{
        stroke-dasharray: 30 10;
        stroke-dashoffset: 0;
    }
    100%{
        stroke-dasharray: 30 10;
        stroke-dashoffset: -40;
    }
}
@mixin show_dt(){
    .dt_circle{
        animation: cirani .3s linear forwards;
    }
    .sencond{
        animation: senani1 .8s linear forwards,senani2 .8s linear .8s infinite;
    }
}

.digital_technology_svg{
    stroke: #fff;
    stroke-linecap: round;
    stroke-width: 1;
    fill: none;
    .dt_circle{
        fill: #fff;
        stroke: #fff;
        transform-origin: 50% 50%;
    }
    .sencond{
        stroke-dasharray: 40;
        stroke-dashoffset: 0;
        transform-origin: 50% 50%;
    }
}