@media screen and (min-width:800px) {
    .home_swiper{
        width: 100%;
        height: 100vh;
        perspective: 100vw;
        position: relative;
        overflow: hidden;
        .swiper_box{
            width: 100%;
            height: 100vh;
            transform-style: preserve-3d;
            transition: all .9s ease-in;
            transform-origin: 50% 50% -50vw;
            &.moving{
                transition: none;
            }
            .swiper_item{
                width: 100%;
                height: 100vh;
                position: absolute;
                overflow: hidden;
                left: 0;
                top: 0;
                transform-origin: 50% 50% -50vw;
                transition: all .9s ease;
                >img{
                    width: 100%;
                    height: 100%;
                }
                &.prev{
                    transform: rotateY(-90deg);
                    opacity: .5;
                }
                &.next{
                    transform: rotateY(90deg);
                    opacity: .5;
                }
                &.next_behind{
                    opacity: 0.1;
                    transform: rotateY(180deg) scale(.1);
                    transition: all 0s ease;
                }
                &.prev_behind{
                    opacity: 0.1;
                    transform: rotateY(-180deg) scale(.1);
                    transition: all 0s ease;
                }
                &.active{
                    opacity: 1;
                }
            }
            
        }
        .swiper_btn{
            position: absolute;
            width: 50px;
            height: 50px;
            top: calc(50vh - 25px);
            background-color: #000;
            border-radius: 50%;
            transition: all .3s ease;
            opacity: 0;
            cursor: pointer;
            z-index: 5;
            box-shadow: 0 0 3px #ccc;
            >img{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 50%;
            }
            &.left_btn{
                left: -51px;
            }
            &.right_btn{
                right: -51px;
            }
        }
        &:hover{
            .swiper_btn{
                opacity: .5;
                &.left_btn{
                    left: calc(1em / 3);
                }
                &.right_btn{
                    right: calc(1em / 3);
                }
                &:hover{
                    opacity: .7;
                    box-shadow: 0 0 10px #fff;
                }
            }
        }
        .swiper_mask{
            position: absolute;
            z-index: 4;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            user-select: none;
            &.moving{
                cursor: move;            
            }
        }
    }
}