$full_size: 90px;
$low_size: 60px;
$theme_color: rgb(255, 152, 24);
$blue: rgb(48, 116, 195);
$red: rgb(239, 59, 0);
@font-face {
    font-family: MengYuanSong;
    src: url("../../assets/font/梦源宋体\ CN_zitidi.com.ttf");
}
.model_index {
    > div {
        transition: all 0.1s ease-in-out;
    }
}
::-webkit-scrollbar {
    display: none;
}
html,
body {
    scrollbar-width: none;
}
svg {
    display: block;
}

@media screen and (min-width: 1024px) {
    .model_index {
        font-size: $full_size;
    }
}

@media screen and (max-width: 1024px) and (min-width: 800px) {
    .model_index {
        font-size: $low_size;
    }
}

@media screen and (max-width: 800px) {
}
