@media screen and (min-width: 800px) {
    #member_structure {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        .member_structure {
            flex: 1;
            display: grid;
            align-items: center;
            justify-items: center;
            .inview{
                overflow: hidden;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    background-color: #fff;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    transform: scale(1,1);
                    transform-origin: 50% 100%;
                }
            }
            img {
                display: block;
                height: 70vh;
            }
            .inview.show {
                &::after{
                    transform: scale(1,0);
                    transition: all .5s ease;
                }
            }
        }
    }
}
