@media screen and (min-width: 800px) {
    #recruit {
        .recruit {
            .job_list {
                width: 70%;
                min-width: 800px;
                margin: 50px auto;
                .job_item {
                    width: 100%;
                    padding: 40px 50px;
                    box-sizing: border-box;
                    position: relative;
                    box-shadow: 5px 5px 20px #ccc;
                    border-radius: 20px;
                    margin-bottom: 100px;
                    .job_title {
                        position: absolute;
                        width: 30%;
                        text-align: center;
                        height: 50px;
                        line-height: 50px;
                        font-size: 24px;
                        font-weight: bold;
                        left: calc(50% - 15%);
                        top: -25px;
                        box-shadow: 3px 3px 10px #ccc;
                        border-radius: 10px;
                        background-color: #fff;
                    }
                    .job_img{
                        float: right;
                        img{
                            max-height: 30vh;
                        }
                    }
                    .rules{
                        text-align: left;
                        .rules_item{
                            margin-bottom: 20px;
                            .rules_title{
                                font-size: 20px;
                            }
                            .details{
                                font-size: 16px;
                                padding: 15px 0 30px 40px;
                                line-height: 1.5;
                            }
                        }
                    }
                }
                .clear{
                    clear: both;
                }
            }
            .nodata {
                height: 200px;
                line-height: 200px;
                text-align: center;
                font-size: 18px;
                color: #777;
            }
        }
    }
}
