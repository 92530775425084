@media screen and (min-width:800px) {
    #company{
        background-color: #f6f6f6;
        padding: 0 1em;
        .company{
            .nodata{
                height: 50vh;
                display: grid;
                align-items: center;
                justify-items: center;
                font-size: 24px;
                color: #aaa;
            }
            .company_box{
                display: grid;
                grid-template-columns: repeat(3,30%);
                grid-gap: 100px 5%;
                padding-top: 50px;
            }
        }
    }
}