@media screen and (min-width: 800px) {
    #warehouse {
        background-color: #f6f6f6;
        .warehouse {
            width: 70%;
            margin: auto;
            --h: 16.276vw;
            .nodata{
                height: 50vh;
                display: grid;
                align-items: center;
                justify-items: center;
                font-size: 24px;
                color: #aaa;
            }
        }
    }
}
