@media screen and (min-width: 800px) {
    .right_nav {
        position: fixed;
        bottom: 10vh;
        right: calc(1em / 3);
        $top_dur: 0.5s;
        $bot_dur: 0.35s;
        > div {
            width: 50px;
            height: 50px;
            background-color: #fff;
            box-shadow: 0 0 3px #ccc;
            opacity: 0.5;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            display: grid;
            align-items: center;
            justify-items: center;
            > svg {
                width: 16px;
                height: 16px;
            }
            &.totop {
                animation: top_hidden $top_dur linear forwards;
            }
            &.tobot {
                animation: bot_hidden $bot_dur linear forwards calc($top_dur - $bot_dur);
            }
            &:hover {
                box-shadow: 0 0 10px #aaa;
                opacity: 1;
            }
        }
        &.show {
            .totop {
                animation: top_show $top_dur linear forwards;
            }
            .tobot {
                animation: bot_show $bot_dur linear forwards;
            }
        }
    }
}
@keyframes top_hidden {
    0% {
        transform: translateY(-60px) rotateZ(0);
    }
    30% {
        transform: translateY(0) rotateZ(360deg);
    }
    100% {
        transform: translateY(calc(10vh + 53px)) rotateZ(1080deg);
    }
}
@keyframes bot_hidden {
    0% {
        transform: translateY(0) rotateZ(0);
    }
    100% {
        transform: translateY(calc(10vh + 53px)) rotateZ(720deg);
    }
}
@keyframes top_show {
    0% {
        transform: translateY(calc(10vh + 53px)) rotateZ(1080deg);
    }
    70% {
        transform: translateY(0) rotateZ(360deg);
    }
    100% {
        transform: translateY(-60px) rotateZ(0);
    }
}
@keyframes bot_show {
    0% {
        transform: translateY(calc(10vh + 53px)) rotateZ(720deg);
    }
    100% {
        transform: translateY(0) rotateZ(0);
    }
}
