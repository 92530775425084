@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0, 0);
    }
    50% {
        opacity: 1;
        transform: scale(1.1, 1.1);
    }
    70% {
        opacity: 1;
        transform: scale(0.9, 0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}
@mixin bounceIn($time: 0.5s) {
    transform-origin: 50% 50%;
    animation: bounceIn $time forwards;
}
@keyframes flicker {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@mixin flicker($time: 0.5s) {
    animation: flicker $time infinite;
}
@keyframes flipInY {
    0% {
        opacity: 0;
        transform: rotateY(90deg);
    }
    30% {
        transform: rotateY(-20deg);
    }
    60% {
        transform: rotateY(20deg);
    }
    70% {
        transform: rotateY(-10deg);
    }
    90% {
        transform: rotateY(10deg);
    }
    100% {
        opacity: 1;
        transform: rotateY(0);
    }
}
@mixin flipInY($time: 1s, $delay: 0s) {
    animation: flipInY $time forwards $delay;
}
@keyframes hue_bg {
    0% {
        filter: hue-rotate(-360deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}
@mixin fleetingtime($color1, $color2, $time: 2s, $hover: false) {
    @if ($hover) {
        &:hover {
            animation: fleeting_time $time infinite linear;
        }
    } @else {
        animation: fleeting_time $time infinite linear;
    }
    @keyframes fleeting_time {
        0% {
            background: linear-gradient(to bottom right, $color1 0%, $color2 100%, $color1 200%, $color2 300%);
        }
        5% {
            background: linear-gradient(to bottom right, $color1 -10%, $color2 90%, $color1 190%, $color2 290%);
        }
        10% {
            background: linear-gradient(to bottom right, $color1 -20%, $color2 80%, $color1 180%, $color2 280%);
        }
        15% {
            background: linear-gradient(to bottom right, $color1 -30%, $color2 70%, $color1 170%, $color2 270%);
        }
        20% {
            background: linear-gradient(to bottom right, $color1 -40%, $color2 60%, $color1 160%, $color2 260%);
        }
        25% {
            background: linear-gradient(to bottom right, $color1 -50%, $color2 50%, $color1 150%, $color2 250%);
        }
        30% {
            background: linear-gradient(to bottom right, $color1 -60%, $color2 40%, $color1 140%, $color2 240%);
        }
        35% {
            background: linear-gradient(to bottom right, $color1 -70%, $color2 30%, $color1 130%, $color2 230%);
        }
        40% {
            background: linear-gradient(to bottom right, $color1 -80%, $color2 20%, $color1 120%, $color2 220%);
        }
        45% {
            background: linear-gradient(to bottom right, $color1 -90%, $color2 10%, $color1 110%, $color2 210%);
        }
        50% {
            background: linear-gradient(to bottom right, $color1 -100%, $color2 0%, $color1 100%, $color2 200%);
        }
        55% {
            background: linear-gradient(to bottom right, $color1 -110%, $color2 -10%, $color1 90%, $color2 190%);
        }
        60% {
            background: linear-gradient(to bottom right, $color1 -120%, $color2 -20%, $color1 80%, $color2 180%);
        }
        65% {
            background: linear-gradient(to bottom right, $color1 -130%, $color2 -30%, $color1 70%, $color2 170%);
        }
        70% {
            background: linear-gradient(to bottom right, $color1 -140%, $color2 -40%, $color1 60%, $color2 160%);
        }
        75% {
            background: linear-gradient(to bottom right, $color1 -150%, $color2 -50%, $color1 50%, $color2 150%);
        }
        80% {
            background: linear-gradient(to bottom right, $color1 -160%, $color2 -60%, $color1 40%, $color2 140%);
        }
        85% {
            background: linear-gradient(to bottom right, $color1 -170%, $color2 -70%, $color1 30%, $color2 130%);
        }
        90% {
            background: linear-gradient(to bottom right, $color1 -180%, $color2 -80%, $color1 20%, $color2 120%);
        }
        95% {
            background: linear-gradient(to bottom right, $color1 -190%, $color2 -90%, $color1 10%, $color2 110%);
        }
        100% {
            background: linear-gradient(to bottom right, $color1 -200%, $color2 -100%, $color1 0%, $color2 100%);
        }
    }
}
