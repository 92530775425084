.wave {
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    position: relative;
    background-color: rgba($color: rgb(0, 0, 0), $alpha: 1);
    aspect-ratio: 1/1;
    overflow: hidden;
    user-select: none;
    .wave_item {
        position: absolute;
        width: 200%;
        left: -50%;
        top: -155%;
        aspect-ratio: 1/1;
        transform-origin: 50% 50%;
        animation: rotatewa 5s linear infinite;
        .wave_item_bg {
            background-color: #fff;
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
}
@keyframes rotatewa {
    0% {
        transform: rotateZ(0);
    }
    100% {
        transform: rotateZ(360deg);
    }
}
