@keyframes fani {
    0%{
        stroke-dashoffset: 18;
    }
    100%{
        stroke-dashoffset: 0;
    }
}
@keyframes sani {
    0%{
        stroke-dashoffset: 3;
    }
    83%{
        stroke-dashoffset: 3;
    }
    100%{
        stroke-dashoffset: 0;
    }
}
@keyframes tani {
    0%{
        opacity: 0;
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes outani {
    0%{
        transform: rotateZ(0);
    }
    15%{
        transform: rotateZ(-180deg);
    }
    50%{
        transform: rotateZ(-180deg);
    }
    65%{
        transform: rotateZ(-360deg);
    }
    100%{
        transform: rotateZ(-360deg);
    }
}
@keyframes tani2 {
    0%{
        transform: rotateY(0) translateY(0);
    }
    15%{
        transform: rotateY(180deg) translateY(-1px);
    }
    30%{
        transform: rotateY(360deg) translateY(0);
    }
    100%{
        transform: rotateY(360deg) translateY(0);
    }
}
@mixin show_trade(){
    .first{
        animation: fani .5s linear forwards;
    }
    .sencond{
        animation: sani .6s linear forwards;
    }
    .third{
        animation: tani .8s linear forwards,tani2 1s linear .8s infinite;
    }
    .out{
        animation: outani 2s linear .8s infinite;
    }
}
.trade_svg{
    stroke: #fff;
    stroke-linecap: round;
    stroke-width: 1;
    fill: none;
    .first{
        stroke-dasharray: 18;
    }
    .sencond{
        stroke-dasharray: 3;
    }
    .third{
        transform-origin: 50% 50%;
    }
    .out{
        transform-origin: 50% 50%;
    }
}