@media screen and (min-width: 800px) {
    .company_item {
        width: 100%;
        aspect-ratio: 17 / 10;
        > div {
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-shadow: 10px 10px 10px #ccc;
            border-radius: 10px;
            transition: all 0.3s ease;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        $minih: 40%;
        .imgbox {
            position: absolute;
            width: 80%;
            height: 80%;
            left: 10%;
            top: 10%;
            display: block;
            transition: all 0.3s ease;
            display: grid;
            justify-items: center;
            align-items: center;
            img {
                max-width: 100%;
                max-height: calc((100vw - 2em) * 0.3 * 10 / 17 * 0.8);
                transition: all 0.3s ease;
            }
        }
        .content {
            $pad_tb: 5%;
            $top: calc($minih + $pad_tb);
            padding-top: calc($top * 10 / 17);
            padding-bottom: calc($pad_tb * 10 / 17);
            height: calc(100% - $top - $pad_tb);
            width: 80%;
            margin: 0 auto;
            transform: translateY(calc(100% - $top));
            transition: all 0.3s ease;
            font-size: 14px;
            text-align: left;
            .title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 7px;
            }
            .contract {
                display: flex;
                > div {
                    margin-right: 10px;
                }
            }
            .btn_box {
                margin-top: 10px;
                display: flex;
                height: 30px;
                line-height: 30px;
                > div {
                    flex: 1;
                    text-align: center;
                }
            }
        }
        &:hover {
            > div {
                transform: translateY(-5px);
                box-shadow: 10px 10px 20px #ccc;
            }
            .imgbox {
                top: 2%;
                height: $minih;
                img {
                    max-height: calc((100vw - 2em) * 0.3 * 10 / 17 * 0.3);
                }
            }
            .content {
                transform: translateY(0);
            }
        }
    }
}
