@import "../model/ani.scss";
@media screen and (min-width: 800px) {
    .follow_ball {
        position: relative;
        background-color: var(--bg);
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            background-image: radial-gradient(#0f0, transparent);
            animation: hue_bg 10s linear infinite;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            left: calc(var(--x) - 1em / 2);
            top: calc(var(--y) - 1em / 2);
            z-index: 2;
            opacity: 0;
        }
        &::after {
            content: "";
            position: absolute;
            inset: 3px;
            background-color: var(--bg);
            filter: opacity(0.7);
            border-radius: 20px;
            z-index: 2;
        }
        > div {
            position: relative;
            z-index: 3;
        }
        &:hover {
            &::before {
                opacity: 1;
            }
        }
        &.auto {
            &::before {
                width: 3em;
                height: 3em;
                left: calc(-3em / 2);
                top: calc(-3em / 2);
                @keyframes mawaru {
                    0% {
                        left: calc(-3em / 2);
                        top: calc(-3em / 2);
                    }
                    25% {
                        top: calc(100% - 3em / 2);
                        left: calc(-3em / 2);
                    }
                    50% {
                        top: calc(100% - 3em / 2);
                        left: calc(100% - 3em / 2);
                    }
                    75% {
                        top: calc(-3em / 2);
                        left: calc(100% - 3em / 2);
                    }
                    100% {
                        left: calc(-3em / 2);
                        top: calc(-3em / 2);
                    }
                }
                animation:
                    hue_bg 10s linear infinite,
                    mawaru 1s linear infinite;
            }
            &::after {
                filter: opacity(0.95);
            }
        }
    }
}
