@media screen and (min-width:800px) {
    .cardbox3d{
        transform-style: preserve-3d;
        perspective: 1000px;
        display: grid;
        justify-items: center;
        align-items: center;
        >div{
            width: max-content;
            height: max-content;
            transform-origin: 50% 50%;
        }
    }
}